a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

// html {
//     font-size: 14px;
// }

// @media (min-width: 768px) {
//     html {
//         font-size: 16px;
//     }
// }

.navbar {
    padding: 0 !important;
    border-bottom: 1px solid #dddddd;
    height: 70px;
    font-size: 14px;
}

.nav-link {
    color: black !important;
}

.landing-nav img {
    height: 70px !important;
}
